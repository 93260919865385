import {ElMessage} from 'element-plus'
import i18n from '@/utils/i18n.js'
import type {Methods, ApiResponse, ResponseData} from '~/utils/types';
import {useUserStore} from "~/stores/user";



const fetchDataFromApi = async <T>(method: Methods, url: string, data: any,errHandle?:any): Promise<T> => {
    // const baseURL = 'https://ssvc.onestepspace.tech';
    const config = useRuntimeConfig();
    const baseURL = config.public.baseURL;
    const apiUrl = baseURL + url;
    // 初始化 options
    const options: any = {
        method: method,
        onRequest({request, options}) {
            const userStore = useUserStore()
            // 設定請求時的 headers
            if (userStore.getToken) {
                options.headers = {
                    'Authorization': 'Bearer ' + userStore.getToken
                };
            }
            // console.log('onRequest options', options);
        },
        onRequestError({request, options, error}) {
            // 捕捉請求時發生的錯誤
            // console.log('onRequestError = ', error);
        },
        onResponse({request, response, options}) {
            // 處理請求回覆資料
            //  console.log('onResponse', response);
            return response._data;
        },
        onResponseError({request, response, options}) {
            // 捕捉請求回覆時發生的錯誤
            // console.log('onResponseError', request);
        }
    };

    // 根據 method 塞資料
    if (method === 'GET' || method === 'DELETE') {
        options.params = data; // GET 和 DELETE 使用 params
    } else {
        options.body = data; // POST, PUT 和 PATCH 使用 body
    }

    try {
        const res: ApiResponse = await $fetch(apiUrl, options)
        if (res.error_code === "00000") {
            return res.data as T;
        } else {

            ElMessage({
                message: `${i18n.global.t('api.' + res.error_code)}`,
                type: 'error',
                duration: 4 * 1000,
                customClass:"messageClass"
            })
            errHandle && errHandle()
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};


export default function<T> (method: Methods, url: string, data?: any,errHandle?: any): Promise<T> {
    method = method.toUpperCase() as Methods
    const settingStore = useSettingStore()
    if (settingStore.isShowLog) {
        console.log('送給 server 的 api url = ' + url)
        console.log('送給 server 的 data = ', data)
    }
    switch (method) {
        case 'POST':
            return fetchDataFromApi('POST', url, data,errHandle)
        case 'GET':
            return fetchDataFromApi('GET', url, {params: data},errHandle)
        case 'DELETE':
            return fetchDataFromApi('DELETE', url, {params: data},errHandle)
        case 'PUT':
            return fetchDataFromApi('PUT', url, data)
        case 'PATCH':
            return fetchDataFromApi('PATCH', url, data)
        default:
            console.log(`未知的 method: ${method}`);
            // 返回一個拒絕的 Promise，確保返回值始終是 Promise
            return Promise.reject(new Error(`未知的 method: ${method}`));
    }

}

