import {defineStore} from 'pinia'
import {getUserInfo} from "@/api/user";
import {logout} from "@/api/auth";
import type {GetUserInfoResponse} from "~/utils/types";

interface useUserStoreState {
    account: string,
    token: string,
    name:string,
    phone:string,
    email:string,
    accountType:number
}



export const useUserStore = defineStore('user', {
    state: ():useUserStoreState => {
        return {
            account: '',
            token: '',
            name:'',
            phone:'',
            email:'',
            accountType:-1
        }
    },
    getters: {
        getToken: (state) => {
            return state.token
        },
        getAccount: (state) => {
            return state.account
        },
        getName: (state) => {
            return state.name
        },
        getPhone: (state) => {
            return state.phone
        },
        getEmail: (state) => {
            return state.email
        },
        getAccountType: (state) => {
            return state.accountType
        },

    },
    // action 支持 async/await 的语法，可以自行添加使用
    // action 里的方法相互之间调用，直接用 this 访问即可
    actions: {
        // 定義更新用戶信息的 action
        async updateUserInfo(): Promise<void>{
            try {
                const  res:GetUserInfoResponse  = await getUserInfo();
                this.updateAccount(res.account);
                this.updateName(res.name);
                this.updatePhone(res.phone);
                this.updateEmail(res.email);
                this.updateAccountType(res.account_type);
            } catch (error) {
                console.log(error);
            }
        },
        async logout(): Promise<void>{

            try {
                await logout(this.account)
            } catch (error) {
                console.log(error)
            }
            this.updateAccount('');
            this.updateToken('');
            this.updateName('');
            this.updatePhone('');
            this.updateEmail('');
            this.updateAccountType(-1);
        },
        updateAccount(email:string):void {
            this.account = email
        },
        updateToken(tokenString:string):void {
            this.token = tokenString
        },
        updateName(nameString:string):void {
            this.name = nameString
        },
        updatePhone(phoneString:string):void {
            this.phone = phoneString
        },
        updateEmail(emailString:string):void {
            this.email = emailString
        },
        updateAccountType(type:number):void {
            this.accountType = type
        }
    },
    /*persist: {
        key: 'officePlatformUser', // key可以自己定义，不填的话默认就是这个store的ID
        storage: persistedState.localStorage, // storage可以填sessionStorage，localStorage
        // paths: ['account', 'userID','token']  // paths是需要存储的字段，不填就是全部

        // cookiesWithOptions
        /!* storage: persistedState.cookiesWithOptions({
      sameSite: 'strict',
    }),*!/
    }*/
    persist: {
        storage: persistedState.cookiesWithOptions({
            sameSite: 'strict',
        }),
    },
})

