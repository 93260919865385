<template>
  <div class="login-wrap">
    <el-icon :size="25"
             v-if="isMobile"
             @click="goHome(route.query), emit('onCloseLoginDialog')"
             class="close-icon">
      <CloseBold/>
    </el-icon>
    <div class="title">{{ title }}</div>
    <InputField :placeholder="$t('placeholder.account')"
                :isValid="valid.isAccountValid"
                :alert="errorMessage.accountErrorMessage"
                :value="info.account"
                class="input-text"
                @change="handleAccountChange"/>
    <InputField :placeholder="$t('login.password')"
                :isValid="valid.isPasswordValid"
                :alert="errorMessage.passwordErrorMessage"
                maxlength="20"
                type="password"
                :value="info.password"
                class="input-text"
                @change="handlePasswordChange"/>

    <div class="flex mb-[20px]">
      <InputField placeholder="驗證碼"
                  :isValid="valid.isCaptchaValid"
                  :alert="errorMessage.captchaErrorMessage"
                  maxlength="4"
                  :value="info.captcha"
                  @change="handleCaptchaChange"
                  class="captcha-input"/>
      <Captcha ref="captchaComp"
               class="h-[50px]"></Captcha>
    </div>

    <div class="forgot-password"
         @click="go('/forgot')">{{ $t('login.forgot_password') }}
    </div>
    <div @click="handleLogin"
         class="login-btn">{{ $t('login.login') }}
    </div>
    <!--    google 登入-->
    <form method="post"
          :action="apiUrl+'/api/auth/google-login'">
      <input type="hidden"
             name="redirect_uri"
             class="g-return"
             :value="homeUrl+'google-return'">
      <button type="submit"
              class="login-google">
        <span class="google-logo"></span>
        {{ $t('login.google_login') }}
        <span class="w-[24px]"></span>
      </button>
    </form>
    <div class="go-register">
      <span> {{ $t('login.no_account') }}</span>
      <span @click="go('/register')"> {{ $t('login.gp_register') }}</span>
      <img src="../assets/image/icon/svg/arrow_circle_right.svg"
           alt="">

    </div>
  </div>
</template>
<script setup>
import {computed, onMounted, ref, watch} from "vue";
import to from 'await-to-js';
import {login} from '../api/auth'
import {accountRegex, passwordRegex} from '../utils/regex.js'
import InputField from '../components/InputField.vue'
import {CloseBold} from '@element-plus/icons-vue'
import {useI18n} from '#imports';
import {useRoute, useRouter} from 'nuxt/app'
import {useSettingStore} from '../stores/setting.js'
import {useUserStore} from '../stores/user.ts'
import {useGoTo} from "@/composables/useGoTo";
import {hashPassword} from "@/utils/parseTool.js";
import Captcha from "@/components/Captcha/Captcha.vue";

const {$fbq} = useNuxtApp()
const props = defineProps({
  isDialog: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['onCloseLoginDialog'])

const apiUrl = ref(import.meta.env.VITE_APP_API_URL)
const homeUrl = ref(import.meta.env.VITE_APP_BASE_HOME_URL)

const settingStore = useSettingStore()
const userStore = useUserStore()
const router = useRouter()
const route = useRoute();
const {t, tm} = useI18n();
const {goHome} = useGoTo();
const isLogin = computed(() => userStore.getToken);
const loginDialogVisible = computed(() => settingStore.isLoginDialogVisible);
const captchaComp = ref(null)


const title = ref(t('login.title'))

const info = ref({
  account: '',
  password: '',
  captcha: ''
})

const valid = ref({
  isAccountValid: true,
  isPasswordValid: true,
  isCaptchaValid: true
})

const errorMessage = ref({
  accountErrorMessage: '',
  passwordErrorMessage: '',
  captchaErrorMessage: ''
})

const isAllValid = computed(() => {
  return valid.value.isAccountValid && valid.value.isPasswordValid && valid.value.isCaptchaValid;
});
const isMobile = computed(() => settingStore.isMobile);

watch(() => loginDialogVisible.value, (val) => {
  if (val) {
    captchaComp.value?.refreshCode()
  }
})

onMounted(() => {
  if (isLogin.value) {
    goHome(route.query)
  }
})
const handleAccountChange = (e) => {
  info.value.account = e.target.value.trim()
  valid.value.isAccountValid = accountRegex(info.value.account)
  errorMessage.value.accountErrorMessage = t('error_message.account')


}
const handlePasswordChange = (e) => {
  info.value.password = e.target.value.trim()
  valid.value.isPasswordValid = passwordRegex(info.value.password)
  errorMessage.value.passwordErrorMessage = t('error_message.password')
}
const handleCaptchaChange = (e) => {
  info.value.captcha = e.target.value.trim()
  valid.value.isCaptchaValid = info.value.captcha.toLowerCase() === captchaComp.value.captcha.toLowerCase()
  errorMessage.value.captchaErrorMessage = t('error_message.captcha')
}

const handleLogin = async () => {
  handleAccountChange({target: {value: info.value.account}})
  handlePasswordChange({target: {value: info.value.password}})
  handleCaptchaChange({target: {value: info.value.captcha}})
  if (!isAllValid.value) {
    return
  }

  const [loginError, loginResponse] = await to(login(info.value.account, hashPassword(info.value.password)));
  if (loginError) {
    handleLoginError(loginError);
    return;
  }

  userStore.updateToken(loginResponse.token);

  const [userInfoError] = await to(userStore.updateUserInfo());
  if (userInfoError) {
    handleLoginError(userInfoError);
    return;
  }
  emit('onCloseLoginDialog');

  if (!props.isDialog) {
    await navigateBackOrHome();
  }

  $fbq('trackCustom', 'OSUserLogin')


}

const handleLoginError = (error) => {
  console.error(error);
  console.log(document.referrer);
  emit('onCloseLoginDialog');
};

const go = (url) => {
  router.push({path: url, query: route.query})
  settingStore.updateLoginDialogVisible(false)
}


/*const navigateBackOrHome = async () => {
  try {
    if (window.history.state.back) {
      await router.back();
    } else {
      await router.push({ path: '/', query: route.query });
    }
  } catch (error) {
    console.error('Navigation error:', error);
  }
};*/

const navigateBackOrHome = async () => {
  try {
    const redirectPath = route.query.redirect;
    if (redirectPath && typeof redirectPath === 'string') {
      await router.push(redirectPath);
    } else if (window.history.state.back) {
      await router.back();
    } else {
      await router.push({path: '/', query: route.query});
    }
  } catch (error) {
    console.error('Navigation error:', error);
    await router.push('/');
  }
};


</script>
<style scoped
       lang="scss">

.login-wrap {
  @apply mx-[auto] relative
  md:w-full;

  .close-icon {
    @apply absolute top-[2px] right-[20px];
  }

  .title {
    @apply text-[20px] text-center mb-[24px];
  }

  .input-text {
    @apply mb-[20px] h-full;
  }

  .forgot-password {
    @apply underline underline-offset-2 ml-[12px] mb-[60px] text-[15px] cursor-pointer
    md:mb-[40px];
  }

  .captcha-input {
    @apply inline-block mr-[12px] w-[133px];
  }

  .captcha {
  }

  .login-btn {
    @apply border mb-[12px] rounded-full text-center bg-osn-yellow-00 text-[16px] font-semibold h-[50px] leading-[50px] cursor-pointer;
  }

  .login-google {
    @apply w-full h-[50px] leading-[50px] flex justify-between items-center
    text-[16px] bg-white rounded-full border border-black font-medium px-[12px] mb-[20px];
    .google-logo {
      @apply w-[24px] h-[24px] bg-contain;
      background-image: url("../assets/image/icon/gooleLogo.svg");
    }
  }

  .go-register {
    @apply flex text-[16px] justify-center cursor-pointer;
    span:nth-child(2) {
      @apply underline underline-offset-2 font-semibold;
    }
  }

}


</style>
